import {createContext} from "react";

const CopyContext = createContext({
    testimonyMessage: '',
    testimonyPerson: '',
    testimonyRole: '',
    brandName: '',
    subsidiary: '',
    tradingAs: '',
    merchantPortalName: '',
    telNo: '',
    telNoDisplay: '',
    step2Body: '',
    step3Body: '',
    quoteMessageEnd: '',
    footer3: '',
    corporationBody: '',
    tcpaBody: '',
    tcpaLink: '',
    plaidBody2: '',
    plaidBody3: ''
});

export default CopyContext;