const copy = {
    testimonyMessage: '“It\'s all been very straightfoward, and the payments are manageable. The process was fast and was clearly explained”',
    testimonyPerson: 'Emma',
    testimonyRole: 'Director, Uniform2go',
    brandName: 'Wayflyer',
    subsidiary: '',
    tradingAs: 'Liberis Capital',
    merchantPortalName: '',
    telNo: '4043643492',
    telNoDisplay: '(404) 364-3492',
    step2Body: 'You will then connect your bank accounts to Liberis’ open banking system so that they can check your revenue, without you having to do any paperwork!',
    step3Body: 'Finally, select a time for a Liberis advisor to call you to agree a price and payment schedule. After this, your application will be processed and once approved, receive the funds to grow your business!',
    quoteMessageEnd: 'you could be eligible for...',
    footer3: '**As of January 2022, 82% of successful applications received funding within 2 working days. Any preliminary approval will be based on the information about your business that is available at the time. You will get a final decision after submitting an actual application.',
    corporationBody: 'Your business has 2+ owners/sharesholders and exists separately from them.',
    tcpaBody: 'By checking the box and clicking “Next” I acknowledge and agree to be contacted by email and telephone and to receive periodic automated text messages at the phone number I provided. Consent is not a condition to purchase. Msg and data rates may apply. Reply “STOP” to unsubscribe.',
    tcpaLink: 'Click to view Liberis’ website terms and conditions and privacy policy.”',
    plaidBody2: 'Connecting to Plaid is essential and helps Liberis to quickly assess your finanicial data to save you paperwork and provide you with an informed funding deicison. You can connect to Plaid now or later with Liberis.',
    plaidBody3: 'Once you select your finanicial institution, just enter your login and password. In a matter of seconds, Plaid encrypts your relevant data and securely shares it with Liberis to assess.'
}

export default copy;