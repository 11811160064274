import { Col, Row, Layout, Typography, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { QuestionCircleOutlined } from '@ant-design/icons'
const { Title, Text } = Typography;

const LeftConfirmDetailsStart = () => {
    return (
        <div className='leftContentBody'>
            <Layout style={{ borderRight: '3px solid #CFB8FF', height:'450px' }}>
                <Content>
                    <Space direction="vertical" size={10}>
                        <Row gutter={16}>
                            <Col span={4}>
                            </Col>
                            <Col>
                                <Title className={'leftTitle'} level={3}>Business Details</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                            </Col>
                            <Col span={18}>
                                <Text className='leftText'>To start, please confirm your details.
                                    <br /><br />
                                    As our quotes are personalised to fit you and your needs, we require some basic business and owner details from you, We also use this information to determine your eligibility. </Text></Col>
                            <Col span={2}>
                            </Col>
                        </Row>
                    </Space>
                </Content>
            </Layout>
        </div>
    );
}

export default LeftConfirmDetailsStart;
