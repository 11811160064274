const copy = {
    testimonyMessage: '“It\'s all been very straightforward, and the payments are manageable as they depend on my card takings for that day!',
    testimonyPerson: 'Ian Knowles',
    testimonyRole: 'Pall Mall Physio',
    brandName: 'Sezzle',
    subsidiary: '',
    tradingAs: 'Sezzle Capital',
    merchantPortalName: '',
    telNo: '8442001938',
    telNoDisplay: '(844) 200-1938',
    step2Body: 'You will then connect your bank accounts to Liberis’ open banking system so that they can check your revenue, without you having to do any paperwork! Don\'t worry, your data is encrypted and remains secure.',
    step3Body: 'Finally, select a time for a Liberis advisor to call you to agree a price and percentage of revenue that works for you. After this, your application will be processed and once approved, receive the funds to grow your business!',
    quoteMessageEnd: 'you could be eligible* for...',
    footer3: '**As of May 2022, 83% of successful applications received funding within 2 working days. Any preliminary approval will be based on the information about your business that is available at the time. You will get a final decision after submitting an actual application.',
    corporationBody: 'Your business has 2+ owners and exists separately from them.',
    tcpaBody: 'By submitting your name, email address, phone number and clicking \'Next\', Liberis will contact you to discuss your business funding needs. By providing your phone number, you represent that you use that number for business purposes.',
    tcpaLink: 'Click to view Liberis’ website terms and conditions and privacy policy.',
    plaidBody2: 'Plaid helps Liberis to quickly assess your finanicial data to save you paperwork and provide you with an informed funding deicison. You can connect to Plaid now or later with Liberis.',
    plaidBody3: 'Once you select your finanicial institution, just enter your login and password. In a matter of seconds, Plaid encrypts your relevant data and securely shares it with Liberis to assess. Your data remains safe and secure throughout the entire process.'
}

export default copy;