import CopyContext from "../CopyContext";
import "./SezzleTheme.css";
import sezzleCopy from "./SezzleCopy";

const SezzleTheme = (props: any) => {
    return (
        <CopyContext.Provider value={sezzleCopy}>
            {props.children}
        </CopyContext.Provider>
    )
}

export default SezzleTheme;