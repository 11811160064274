import { Button, Checkbox, Col, Divider, Form, Layout, Row, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import { ArrowRightOutlined } from '@ant-design/icons'
import confetti from 'canvas-confetti';
import { useEffect, useState } from 'react';

const { Text } = Typography;

interface SuccessPageProps {
    nextPage: (currentPage: string, nextPage: string) => void;
    trackPage: () => void;
}


const SuccessPage = ({ nextPage }: SuccessPageProps) => {

    const [canRequestCallback, setCanRequestCallback] = useState<boolean>(false);

    useEffect(() => {
        for (let x = 0; x < 10; x++) {
            confetti({
                particleCount: 100,
                startVelocity: 30,
                spread: 1800,
                origin: {
                    x: Math.random(),
                    // since they fall down, start a bit higher than random
                    y: Math.random() - 0.2
                }
            });

        }
    }, [])
    return (
        <div className='successLayout'>
            <Layout>
                <Row>
                    <Col xs={0} md={24}>
                        <Row>
                            <Col xs={1} md={5}></Col>
                            <Col xs={22} md={14}>
                                <Row className='successContent'>
                                    <Space direction='vertical' size={40}>
                                        <Col span={24}><div className='thumbs'></div></Col>
                                        <Col span={24}>
                                            <Text className='successTitle'>Application submitted</Text>
                                        </Col>
                                        <Col style={{ margin: '0 auto' }} span={18}>
                                            <Text className='successText'>Thanks for your interest in the Merchant Cash Advance Program. We are reviewing your application details and a member of our team will be in touch shortly with your offer.</Text>
                                        </Col>

                                        <Col style={{ margin: '0 auto' }} span={18}>
                                            <Text className='successText'>If you can't wait, you can reach out directly below!</Text>
                                        </Col>

                                    </Space>
                                    <Col span={4} />
                                    <Col span={16} style={{ marginTop: '40px' }}>
                                        <Row>
                                            <Col span={24} style={{ paddingTop: '8px' }}><Button className='bookCallButton' onClick={() => nextPage('complete', 'call-now')}>Call Now</Button></Col>
                                        </Row>
                                    </Col>
                                    <Col span={4} />
                                </Row >
                            </Col>
                            <Col xs={1} md={5}></Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={0} style={{ padding: '10px' }}>
                        <div className='mobileSuccess'>
                            <Row>
                                <Space direction='vertical' size={40}>
                                    <Col span={24}><div className='thumbs'></div></Col>
                                    <Col span={24}>
                                        <Text className='successTitle'>Application submitted</Text>
                                    </Col>
                                    <Col style={{ margin: '0 auto' }} span={24}>
                                        <Text className='successText'>Thanks for your interest in the Merchant Cash Advance Program. We are reviewing your application details and a member of our team will be in touch shortly with your offer.</Text>
                                    </Col>

                                    <Col style={{ margin: '0 auto' }} span={24}>
                                        <Text className='successText'>If you can't wait, you can reach out directly below!</Text>
                                    </Col>

                                </Space>
                                <Col span={24} style={{ marginTop: '40px' }}>
                                    <Row>
                                        <Col span={24} style={{ paddingTop: '8px' }}><Button className='bookCallButton' onClick={() => nextPage('complete', 'call-now')}>Call Now</Button></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>
            </Layout >
        </div >)
}


export default SuccessPage