import { Button, Card, Col, Divider, Input, Layout, Row, Slider, Space, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { Config } from "./config";
import CopyContext from "./partners/CopyContext";
const { Text } = Typography;

interface LandingProps {
    trackPage: () => void;
    config: Config;
}

const Landing = (props: LandingProps) => {
    props.trackPage();
    return <Layout className="wideLayout landing">
        <QuotePage config={props.config} />
        <StepsPage />
        <CardPage />
        <Testimonial />
        <FooterQuote config={props.config} />
        <Footer config={props.config} />
    </Layout>
}

interface ConfigProps {
    config: Config
}

const QuotePage = ({ config }: ConfigProps) => {

    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const copy = useContext(CopyContext);

    const validateCode = async () => {
        setInvalidCode(false);
        setIsLoading(true);
        if (!code) {
            setInvalidCode(true);
            setIsLoading(false);
            return;
        }
        try {
            const valid = await axios.get(`${config.validateUrl}?code=${code}`);
            valid.status === 200 ? changePage('', 'business-details') : setInvalidCode(true);
            setIsLoading(false);
        }
        catch (e) {
            setInvalidCode(true);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmCode = urlParams.get('utm_term');

        if (utmCode) {
            setCode(utmCode);
        }
    }, [])

    const changePage = (currentRoute: string, nextRoute: string) => {
        localStorage.setItem('currentPage', nextRoute)
        window.history.pushState({}, '', currentRoute); window.location.href = nextRoute;
    }

    const applyNow = () => {
        setIsLoading(true);
        changePage('', 'business-details');
        setIsLoading(false);
    }

    return <div className="quotePage">

        <Row className="">
            <Col xs={0} md={1}></Col>
            <Col className="header-logo-container" xs={24} md={12}>
                <div className="header-logo" />
                <div className="brand-slogan">Brought to you by Liberis in partnership with {copy.tradingAs}</div>
            </Col>


            <Col xs={0} md={10}>
                <Row gutter={16}>
                    <Col className="quoteHeaderText" span={14}>
                        <div>Need Help? Speak to our team!</div>
                    </Col>
                    <Col span={6}>
                        <Button className="callNowButton"><a href={'/call-now'}>Call Now</a></Button>
                    </Col>
                    <Col style={{ cursor: 'pointer' }} className="quoteHeaderText" span={4}>
                        <a className="quoteHeaderText" href={'/faqs'}>FAQs</a>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row style={{ marginTop: '2%' }}>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={11} className={'quoteLeft'}>
                <Row>
                    <Col span={24} className="checklist-container">
                        <Space direction="vertical" size={20}>
                            <div>
                                <Text className="quoteLeftTitleWhite">Grow your business</Text></div>
                            <div><Text className="quoteLeftTitleWhite">with revenue based finance through Liberis</Text></div>
                            <div className="quoteLeftRow">
                                <div className="checklist-icon-1" />
                                <Text className="quoteLeftText">Pay as a fixed % of your revenue</Text></div>
                            <div className="quoteLeftRow">
                                <div className="checklist-icon-2" />
                                <Text className="quoteLeftText">70% approval rate</Text></div>
                            <div className="quoteLeftRow">
                                <div className="checklist-icon-3" />
                                <Text className="quoteLeftText">Receive funds in as little as 1-2 days**</Text></div>
                            <Col xs={24} md={0}>
                                {!!config.useAccessCode ?
                                    <Card className="startCard">
                                        <Col xs={24} md={24}><Text className="quoteCardBold">Enter your</Text><Text className="quoteCardGreen"> {copy.tradingAs} access code</Text><Text className="quoteCardBold"> to begin.</Text></Col>
                                        <br />
                                        <Space direction="vertical">
                                            <Col span={24} style={{ position: 'relative' }}> <Input placeholder='Code' value={code} status={invalidCode ? 'error' : ''} onChange={(e) => setCode(e.target.value)} />
                                            </Col>
                                            <Col span={24}>
                                                <Button className="quoteButton" loading={isLoading} onClick={() => validateCode()}>Apply Now</Button>
                                            </Col>
                                        </Space>
                                        {invalidCode && <Col span={24}><Text style={{ textAlign: 'center' }} type='danger'>Invalid code</Text></Col>}
                                    </Card> : <Button className="quoteButton" loading={isLoading} onClick={() => applyNow()}>Apply Now</Button>}
                            </Col>
                        </Space>
                    </Col>
                    <Col xs={0} md={24} className="quoteTestimonailCardContainer">
                        <Card className='quoteTestimonailCard'>
                            <Row>
                                <Col style={{ marginTop: '20px' }} span={6}>
                                    <div className="testimonial-logo" />
                                </Col>
                                <Col span={18}>
                                    <Row>{copy.testimonyMessage}</Row>
                                    <Row>
                                        <Col span={8} className="testimonial-person">{copy.testimonyPerson}</Col>
                                        <Col span={10} className="testimonial-role">{copy.testimonyRole}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={1}></Col>
            <Col xs={0} md={9}>
                <Quote config={config} /></Col>
            <Col span={1}></Col>
            <Col span={1}></Col>

        </Row >
        <Card className='quoteTestimonailCard'>
            <Row>
                <Col style={{ marginTop: '20px' }} span={6}>
                    <div className="testimonial-logo" />
                </Col>
                <Col span={18}>
                    <Row>{copy.testimonyMessage}</Row>
                    <Row>
                        <Col span={8} className="testimonial-person">{copy.testimonyPerson}</Col>
                        <Col span={10} className="testimonial-role">{copy.testimonyRole}</Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    </div >
}

const StepsPage = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const copy = useContext(CopyContext);
    const step1Header = 'Confirm your business and ownership details'
    const step1Body = "Our online application will start by asking you a few basic questions about you and your business."

    const step2Header = 'Connect your bank accounts'

    const step3Header = 'Schedule a call'

    return <div className="stepsPage">
        {currentStep === 1 && <Step header={step1Header} body={step1Body} stepIndex={currentStep} selectedStep={currentStep} changeStep={setCurrentStep} />}
        {currentStep === 2 && <Step header={step2Header} body={copy.step2Body} stepIndex={currentStep} selectedStep={currentStep} changeStep={setCurrentStep} />}
        {currentStep === 3 && <Step header={step3Header} body={copy.step3Body} stepIndex={currentStep} selectedStep={currentStep} changeStep={setCurrentStep} />}
    </div>
}

interface StepProps {
    selectedStep: number;
    changeStep: React.Dispatch<React.SetStateAction<number>>;
    header: string,
    body: string,
    stepIndex: number;
}
const Step = ({ selectedStep, changeStep, header, body, stepIndex }: StepProps) => {
    return <div className="step">
        <Row>
            <Col span={1}></Col>
            <Col className="stepLeft" xs={24} md={10}>
                <Row>
                    <Space direction="vertical" size={40}>
                        <Row>
                            <Col xs={2} md={0}></Col>
                            <Col style={{ marginTop: '-80px' }} xs={20} md={24}>
                                <div>
                                    <div className="stepPageTitle">Apply for quick, flexible financing in</div>
                                    <div className="stepPageTitleGreen">3 simple steps.</div>
                                </div>
                            </Col>
                            <Col xs={2} md={0}></Col>
                        </Row>
                        <Col xs={10} md={0} className="stepRight stepRightMobile">
                            <div className={`step-${stepIndex}`} />
                        </Col>
                        <Row>
                            <Col xs={2} md={0}></Col>

                            <Col md={24} xs={20}>
                                <Row gutter={16}>
                                    <Col span={8}><Button onClick={() => changeStep(1)} className={`stepButton ${selectedStep === 1 ? 'selected' : ''}`}>Step 1</Button></Col>
                                    <Col span={8}><Button onClick={() => changeStep(2)} className={`stepButton ${selectedStep === 2 ? 'selected' : ''}`}>Step 2</Button></Col>
                                    <Col span={8}><Button onClick={() => changeStep(3)} className={`stepButton ${selectedStep === 3 ? 'selected' : ''}`}>Step 3</Button></Col>
                                </Row>
                            </Col>
                            <Col xs={2} md={0}></Col>

                        </Row>

                        <Col span={24}>
                            <Row>
                                <Col xs={2} md={0}></Col>
                                <Col xs={20} className="stepPageHeader">{header}</Col>
                                <Col xs={2} md={0} ></Col>
                                <Col xs={2} md={0}></Col>
                                <Col xs={20} className="stepPageText">{body}</Col>
                                <Col xs={2} md={0}></Col>
                            </Row>
                        </Col>
                    </Space>
                </Row>

            </Col>
            <Col xs={0} md={12} className="stepRight">
                <div className={`step-${stepIndex}`} />
            </Col>
            <Col span={1}></Col>
        </Row>
    </div>
}

const CardPage = () => {
    return <div className="cardPage">
        <Space direction="vertical" size={200}>
            <Row className="cardPageHeader">
                <Col span={24}><Text className="firstRow">Simple. Stress-Free.</Text></Col>
                <Col span={24}><Text>Like Business Finance Should Be.</Text></Col>
            </Row>

            <Row style={{ textAlign: 'center' }} gutter={[0, 120]}>
                <Col xs={1} md={2} />
                <Col xs={22} md={6}>
                    <Card className="cardPageCard">
                        <div style={{ marginTop: '-120px' }} className="cardImage">
                            <div className="card-1" />
                        </div>
                        <div className="cardTitle">Quick and hassle free</div>
                        <div className="cardBody">Following successful application, you could have the funds in your account in as little as 1-2  days**
                        </div>
                    </Card>
                </Col>
                <Col xs={1} md={1} />
                <Col xs={1} md={0} />
                <Col xs={22} md={6}>
                    <Card className="cardPageCard">
                        <div style={{ marginTop: '-120px', marginBottom: '55px' }} className="cardImage">
                            <div className="card-2" />
                        </div>
                        <div className="cardTitle">Transparent pricing</div>
                        <div className="cardBody">One price, agreed upfront.<br /> No late or hidden fees.</div>
                    </Card>
                </Col>
                <Col xs={1} md={0} />
                <Col xs={1} md={1} />
                <Col xs={22} md={6}>
                    <Card className="cardPageCard" style={{ marginBottom: '40px' }}>
                        <div style={{ marginTop: '-120px', marginBottom: '40px' }} className="cardImage">
                            <div className="card-3" />
                        </div>
                        <div className="cardTitle">Simple payments</div>
                        <div className="cardBody">Payment is revenue based -
                            pay Liberis through an agreed weekly percentage***</div>
                    </Card>
                </Col>
                <Col xs={1} md={2} />

            </Row>
        </Space>
    </div >
}

const Testimonial = () => {
    return <div className="testimonial">
        <Row className="testimony">
            <Col className="testimony-left" xs={1} md={7}></Col>
            <Col className="text" xs={22} md={10}>" The funding has been a great enabler, allowing us to fast forward our growth. Without it, we'd be a year behind where we are now. It works perfectly for us. "</Col>
            <Col className="testimony-right" xs={1} md={7}></Col>
        </Row>
        <Row className="testimonyName">
            <Col className="testimony-name-company" xs={24} md={0}>
                <div className="name">Keith Swain</div>
                <div className="company">Kenilworth Carpets</div>
            </Col>
            <Col className="testimony-name-only" style={{ textAlign: 'right', marginTop: '30px', }} xs={0} md={6}>
                Keith Swain
            </Col>
            <Col xs={24} md={12}>
            </Col>
            <Col className="testimony-company-only" style={{ textAlign: 'left', marginTop: '30px', }} xs={0} md={6}>
                Kenilworth Carpets
            </Col>
        </Row>
    </div>
}

const FooterQuote = ({ config }: ConfigProps) => {
    return <div className="footer-quote">
        <Row gutter={0}>
            <Col xs={2} md={3}></Col>
            <Col className="footerQuoteLeft footerQuoteMobile" xs={20} md={0}><Text className="footerQuoteLeftBold">To get a quote,</Text><Text className="footerQuoteLeftText"> start by telling us your average monthly revenue</Text><Col xs={24} md={12}></Col></Col>
            <Col className="footerQuoteLeft" xs={0} md={9}><Text className="footerQuoteLeftBold">To get a quote,</Text><Text className="footerQuoteLeftText"> start by telling us your average monthly revenue</Text><Col xs={24} md={12}></Col>
            </Col>
            <Col xs={2} md={0}></Col>
            <Col xs={2} md={0}></Col>
            <Col xs={20} md={9} style={{ marginTop: '100px' }}><Quote config={config} /></Col>
            <Col xs={2} md={3}></Col>
        </Row>
    </div>

}

const Footer = ({ config }: ConfigProps) => {
    const copy = useContext(CopyContext);

    return <div className="footer">
        <Row>
            <Col xs={2} md={4}></Col>
            <Col xs={20} md={16}>
                This revenue finance product is provided by Liberis US, Inc. (“Liberis”). This is a revenue based finance product; Terms and conditions apply.
                <br /><br />

                *Amounts may be subject to change depending on your credit profile at enquiry. Liberis reserves the right to decline applications for affordability concerns, active bankruptcy, or failure to pass their compliance checks.

                <br /><br />

                {copy.footer3}

                <br /><br />
                ***{copy.tradingAs} Customers can adjust their payments if revenue decreases

                <br /><br />
            </Col>
            <Col xs={2} md={4}></Col>
        </Row>
        <Row className="footerLinks">
            <Col xs={2} md={4}></Col>
            <Col xs={6} md={3}>
                <a target="_blank" href="https://www.liberis.com/tcs">Privacy Policy</a>
            </Col>
            <Col xs={8} md={4}>
                <a target="_blank" href="https://www.liberis.com/tcs">Terms and conditions</a>
            </Col>
            <Col xs={6} md={2}>
                <a href={'/faqs'}>FAQs</a>
            </Col>
            <Col xs={2} md={4}></Col>
        </Row>
    </div >

}

const Quote = ({ config }: ConfigProps) => {
    const [avgValue, setAvgValue] = useState<number>(50000);
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const copy = useContext(CopyContext);

    const validateCode = async () => {
        setInvalidCode(false);
        setIsLoading(true);
        if (!code) {
            setInvalidCode(true);
            setIsLoading(false);
            return;
        }
        try {
            const valid = await axios.get(`${config.validateUrl}?code=${code}`);
            valid.status === 200 ? changePage('', 'business-details') : setInvalidCode(true);
            setIsLoading(false);
        }
        catch (e) {
            setInvalidCode(true);
            setIsLoading(false)
        }
    }

    const applyNow = async () => {
        setIsLoading(true);
        changePage('', 'business-details');
        setIsLoading(false);
    }

    const changePage = (currentRoute: string, nextRoute: string) => {
        localStorage.setItem('currentPage', nextRoute)
        window.history.pushState({}, '', currentRoute); window.location.href = nextRoute;
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmCode = urlParams.get('utm_term');

        if (utmCode) {
            setCode(utmCode);
        }
    }, [])

    return <div>
        <Card className="quoteCard">
            <Row>
                <Col xs={24} md={18}><Text className="quoteCardBold">Based on your</Text><Text className="quoteCardGreen"> average monthly revenue,</Text><Text className="quoteCardBold"> {copy.quoteMessageEnd}</Text>
                </Col>
                <Col xs={24} md={6}><div className="quoteBox">${avgValue * 1.5}</div></Col>
            </Row>
            <Row style={{ textAlign: 'center' }}>
                <Col span={24}>
                    <Slider onChange={(x) => { setAvgValue(x) }} tooltipVisible={false} step={5000} min={2500} max={100000} className="quoteSlider" defaultValue={avgValue} />
                </Col>
                <Col xs={8} md={4}>
                    <div className="quoteLimitBox">$2500</div>
                </Col>
                <Col xs={8} md={16}>
                    <div className="quoteLimitBox" style={{ fontSize: '20px' }}>${avgValue}</div>
                    <div className="quoteLimitMessage">Average monthly revenue</div>
                </Col>
                <Col xs={8} md={4}>
                    <div style={{ float: 'right' }} className="quoteLimitBox">$100k</div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col md={24} xs={0}>
                    {!!config.useAccessCode ?
                        <Space size={10}>
                            <Col xs={24} md={24}><Text className="quoteCardBold">Enter your</Text><Text className="quoteCardGreen"> {copy.tradingAs} access code</Text><Text className="quoteCardBold"> to begin.</Text>
                            </Col>
                            <Col span={24} style={{ position: 'relative' }}> <Input placeholder='Code' value={code} status={invalidCode ? 'error' : ''} onChange={(e) => setCode(e.target.value)} />
                                {invalidCode && <Text style={{ position: 'absolute', top: '50px', left: '0px' }} type='danger'>Invalid code</Text>}</Col>
                            <Col span={24}>
                                <Button className="quoteButton" loading={isLoading}><a onClick={() => validateCode()}>Apply Now</a></Button>
                            </Col>
                        </Space>
                        : <Button className="quoteButton" loading={isLoading} onClick={() => applyNow()}>Apply Now</Button>}
                </Col>
                <Col md={0} xs={24}>
                    {!!config.useAccessCode ?
                        <Space size={10} direction="vertical">
                            <Col xs={24} md={24}><Text className="quoteCardBold">Enter your</Text><Text className="quoteCardGreen"> {copy.tradingAs} access code</Text><Text className="quoteCardBold"> to begin.</Text>
                            </Col>
                            <Col span={24} style={{ position: 'relative' }}> <Input placeholder='Code' value={code} status={invalidCode ? 'error' : ''} onChange={(e) => setCode(e.target.value)} />
                                {invalidCode && <Text style={{ position: 'absolute', top: '50px', left: '0px' }} type='danger'>Invalid code</Text>}</Col>
                            <Col span={24}>
                                <Button className="quoteButton" loading={isLoading} onClick={() => validateCode()}>Apply Now</Button>
                            </Col>
                        </Space>
                        : <Button className="quoteButton" loading={isLoading} onClick={() => applyNow()}>Apply Now</Button>}
                </Col>
            </Row>
        </Card>
    </div >
}

export default Landing;