import { Button, Card, Checkbox, Col, Divider, Form, Input, InputNumber, Layout, Row, Select, Slider, Space, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useContext, useEffect, useState } from 'react';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import ButtonFooter from './ButtonFooter';
import { Applicant, Application } from './App';
import moment from 'moment';
import CopyContext from "./partners/CopyContext";

const { Option } = Select;

const { Text } = Typography;

interface RightOwnerDetailsProps {
    showBackButton: boolean;
    multipleOwners: boolean;
    application: Application;
    updateApplication: (application: Application) => void;
    nextPage: (currentPage: string, nextPage: string) => void;
}
const RightOwnerDetails = ({ showBackButton, multipleOwners, application, updateApplication, nextPage }: RightOwnerDetailsProps) => {

    const copy = useContext(CopyContext);
    const onFinish = (values: any) => {
        console.dir(values);
        const applicants = [];
        for (let x = 1; x <= additionalApplicants + 1; x++) {
            console.log([values[`applicant${x}.dobYear`], values[`applicant${x}.dobMonth`], values[`applicant${x}.dobDay`]])
            const birthDate = moment([Number(values[`applicant${x}.dobYear`]), Number(values[`applicant${x}.dobMonth`]), Number(values[`applicant${x}.dobDay`])]).toDate();
            console.log(birthDate)
            const applicant: Applicant = {
                firstName: values[`applicant${x}.firstName`],
                lastName: values[`applicant${x}.lastName`],
                emailAddress: values[`applicant${x}.emailAddress`],
                phoneNumber: values[`applicant${x}.phoneNumber`],
                ownershipPercentage: values[`applicant${x}.ownershipPercentage`],
                birthDate: new Date(birthDate),
                dobDay: values[`applicant${x}.dobDay`],
                dobMonth: values[`applicant${x}.dobMonth`],
                dobYear: values[`applicant${x}.dobYear`],
                socialSecurityNumber: values[`applicant${x}.socialSecurityNumber`]
            }
            applicants.push(applicant);
        }
        application.applicants = applicants;
        application.tcpaConsent = values.tcpaConsent;
        application.productCommsConsent = values.productCommsConsent;
        application.creditSearchConsent = values.creditSearchConsent;
        updateApplication(application);
        multipleOwners ? nextPage('applicants', 'plaid-setup') : nextPage('applicant', 'plaid-setup')
    };

    const removeApplicant = (index: number) => {
        const values = form.getFieldsValue();
        setAdditionalApplicants(additionalApplicants - 1);
        const applicants = application.applicants.filter(x => application.applicants.indexOf(x) !== index);
        console.log(applicants)
        updateApplication({ ...application, applicants: applicants });
    }

    useEffect(() => {
        if (additionalApplicants === 0 && application.applicants.length > 1) {
            setAdditionalApplicants(application.applicants?.length - 1)
        }
    })

    const [form] = Form.useForm();
    const [additionalApplicants, setAdditionalApplicants] = useState<number>(0)

    return (
        <div>
            <Layout>
                <Content>
                    <Row>
                        <Col xs={2} md={4}></Col>
                        <Col className='formBody' style={{ maxHeight: '100% !important', overflow: 'visible' }} xs={20} md={16}>
                            <Space direction='vertical' size={40}>
                                <div><Text className='rightTitle'>Owner Details</Text></div>
                                <Form
                                    name="basic"
                                    requiredMark={false}
                                    layout="vertical"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 22,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    form={form}

                                >
                                    <Row gutter={0}>
                                        <Space direction='vertical' size={20}>
                                            <div><Text className='rightSubtitle'>Your Details</Text></div>
                                            <div><OwnershipForm multipleOwners={multipleOwners} applicantNumber={1} applicant={application.applicants[0] ? application.applicants[0] : undefined} /></div>
                                            {multipleOwners && <Button className='addOwnerButton' onClick={() => setAdditionalApplicants(additionalApplicants < 8 ? additionalApplicants + 1 : additionalApplicants)}>Add Owner</Button>}
                                            <div>
                                                {Array.from(Array(additionalApplicants).keys()).map(x => {
                                                    return <div>
                                                        <Divider />
                                                        <Space direction={'vertical'} size={30}>
                                                            <Text className='rightSubtitle'>Owner {x + 2} Details</Text>

                                                            <OwnershipForm multipleOwners={multipleOwners} applicantNumber={x + 2} applicant={application.applicants.length > 0 ? application.applicants[x + 1] : undefined} />
                                                        </Space>
                                                    </div>
                                                })}
                                            </div>

                                        </Space>


                                        <Col span={24}>
                                            <Form.Item
                                                name="creditSearchConsent"
                                                valuePropName="checked"
                                                initialValue={application.creditSearchConsent}
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                                    },
                                                ]}
                                            >
                                                <Checkbox>
                                                    *To generate your quote, Liberis need consent to perform credit check[s]. By clicking “Next”, I authorise Liberis to request reports from Credit Bureaux reporting agencies, business credit reporting agencies, and/or other data providers relating to the named business and business owner applying in this application.
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="tcpaConsent"
                                                valuePropName="checked"
                                                initialValue={application.tcpaConsent}
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                                    },
                                                ]}
                                            >
                                                <Checkbox>
                                                    *{copy.tcpaBody}  <a style={{ color: 'rgb(134, 77, 255)' }} target="_blank" href="https://www.liberis.com/tcs">{copy.tcpaLink}</a>
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="productCommsConsent"
                                                valuePropName="checked"
                                                initialValue={application.productCommsConsent}
                                            >
                                                <Checkbox>
                                                    Let us know if you are happy for Liberis to contact you using this information you've provided above about Liberis' products and services. You may unsubscribe at any time. <a style={{ color: 'rgb(134, 77, 255)' }} target="_blank" href="https://www.liberis.com/tcs">Please see Liberis’ privacy policy for more info.</a>                                          </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <ButtonFooter showBackButton={showBackButton} disabled={false} onSubmit={() => { }} />
                                </Form>
                            </Space>
                        </Col>
                        <Col xs={2} md={4}></Col>

                    </Row >
                </Content >
            </Layout >
        </div >)
}


interface OwnershipFormProps {
    multipleOwners: boolean,
    applicantNumber: number,
    applicant?: Applicant
}
const OwnershipForm = ({ applicantNumber, applicant, multipleOwners }: OwnershipFormProps) => {
    console.log(multipleOwners)
    const [ownershipPercentage, setOwnership] = useState<number>(multipleOwners ? 50 : 100);
    const now = new Date().getUTCFullYear();
    const years = Array(now - (now - 100)).fill('').map((v, idx) => now - idx);
    return <div>
        <Row>
            <Col xs={24} md={12}>
                <Form.Item
                    label="First Name"
                    name={`applicant${applicantNumber}.firstName`}
                    initialValue={applicant?.firstName}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your first name.',
                        },
                    ]}
                >
                    <Input placeholder='Jane' />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Last Name"
                    name={`applicant${applicantNumber}.lastName`}
                    initialValue={applicant?.lastName}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input your last name.',
                        },
                    ]}
                >
                    <Input placeholder='Doe' />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Email Address"
                    name={`applicant${applicantNumber}.emailAddress`}
                    initialValue={applicant?.emailAddress}
                    validateFirst={true}
                    validateTrigger='onBlur'
                    rules={[
                        {
                            required: true,
                            message: 'Please input a valid email address.',
                            pattern: /^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$/
                        },
                    ]}
                >
                    <Input type={'email'} placeholder='jane.doe@email.com' />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Phone Number"
                    name={`applicant${applicantNumber}.phoneNumber`}
                    initialValue={applicant?.phoneNumber}
                    validateFirst={true}
                    validateTrigger='onBlur'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your phone number.',
                            pattern: /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/
                        },
                    ]}
                >
                    <Input placeholder='(415) 555-2671' />
                </Form.Item>
            </Col>

            {applicantNumber === 1 &&
                <Col xs={24}>
                    <Form.Item
                        label="Social Security Number"
                        name={`applicant${applicantNumber}.socialSecurityNumber`}
                        initialValue={applicant?.socialSecurityNumber}
                        validateFirst={true}
                        validateTrigger='onBlur'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Enter your social security number - 000-00-0000',
                                pattern: /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
                            },
                        ]}
                    >
                        <Input placeholder='000-00-0000' />
                    </Form.Item>
                </Col>
            }


            <Col xs={8} md={5}>
                <Form.Item
                    label="Date of birth"
                    name={`applicant${applicantNumber}.dobDay`}
                    initialValue={applicant?.dobDay}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input day'
                        },
                    ]}
                >
                    <Select optionFilterProp="children" placeholder="1">
                        {Array.from(Array(31).keys()).map(x => { return <Option value={`${x + 1}`}>{x + 1}</Option> })}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={8} md={5}>
                <Form.Item
                    label="‎"
                    name={`applicant${applicantNumber}.dobMonth`}
                    initialValue={applicant?.dobMonth}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input month'
                        },
                    ]}
                >
                    <Select optionFilterProp="children" placeholder="10">
                        {Array.from(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']).map((key, x) => { return <Option value={`${x}`}>{key}</Option> })}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={8} md={5}>
                <Form.Item
                    label="‎"
                    name={`applicant${applicantNumber}.dobYear`}
                    initialValue={applicant?.dobYear}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please input year',
                        },
                    ]}
                >
                    <Select optionFilterProp="children" placeholder="1971">
                        {years.map(x => { return <Option value={`${x}`}>{x}</Option> })}                    </Select>
                </Form.Item>
            </Col>


            <Col xs={24} md={24}>
                <Col xs={24} md={17}>
                    <Row>
                        <Col xs={8} md={8}>
                            <Form.Item
                                label="Ownership percentage"
                                name={`applicant${applicantNumber}.ownershipPercentage`}
                                initialValue={applicant?.ownershipPercentage ? applicant.ownershipPercentage : multipleOwners ? 50 : 100}
                                hasFeedback
                            >
                                <InputNumber disabled={!multipleOwners} placeholder={ownershipPercentage.toString()} addonAfter="%" />
                            </Form.Item>
                        </Col>
                        <Col xs={16} md={16}>
                            <Form.Item
                                name={`applicant${applicantNumber}.ownershipPercentage`}
                                initialValue={applicant?.ownershipPercentage ? applicant.ownershipPercentage : multipleOwners ? 50 : 100}
                                hasFeedback
                            >
                                <Slider
                                    min={1}
                                    disabled={!multipleOwners}
                                    max={100}
                                    style={{ marginTop: '47px' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Col>
        </Row >
    </div >
}

export default RightOwnerDetails