import PageLayout from "./Page";
import LeftConfirmDetailsStart from './LeftConfirmDetailsStart';
import LeftScheduleCall from './LeftScheduleCall';
import RightBusinessDetails from './RightBusinessDetails';
import RightBusinessEntity from './RightBusinessEntity';
import RightBusinessOwnership from './RightBusinessOwnership';
import RightOwnerDetails from './RightOwnerDetails';
import RightPlaidSubmission from './RightPlaidSubmission';
import SuccessPage from './RightSuccess';
import CallNow from './CallNow';
import RightCalendar from './RightCalendar';
import { useContext, useEffect, useState } from "react";
import {
    BrowserRouter,
    Route,
} from "react-router-dom";
import axios from "axios";
import FAQs from "./FAQ";
import Landing from "./Landing";
import LeftPlaidConnection from "./LeftPlaidConnection";
import mixpanel from 'mixpanel-browser';
import {Config} from "./config";
import Theme from "./partners/Theme";
import CopyContext from "./partners/CopyContext";

export interface Application {
    applicationId?: string,
    liberisId?: string,
    businessEIN: string,
    businessName: string,
    timeInBusiness: { years?: number, months?: number },
    address: Address,
    businessType?: BusinessType,
    ownershipStructure?: BusinessOwnership,
    applicants: Applicant[],
    productCommsConsent: boolean,
    creditSearchConsent: boolean,
    tcpaConsent: boolean,
    averageTransactionVolumes?: number
}


interface ApplicantCreate {
    isPrimary: boolean,
    firstName: string,
    lastName: string,
    ownershipPercentage: number,
    email: string,
    telephone: string,
    birthdate: Date,
    socialSecurityNumber?: string;
}

interface ApplicationCreateRequest {
    currency: string,
    applicants: ApplicantCreate[],
    accountLegalName: string,
    accountTradingName: string,
    accountLegalConstitution: string,
    accountEin: string,
    accountTimeInBusiness: number,
    accountRegisteredAddressStreet?: string,
    accountRegisteredAddressCity?: string,
    accountRegisteredAddressCountry?: string,
    accountRegisteredAddressState?: string,
    accountRegisteredAddressZip?: string,
    accountTradingAddressStreet?: string,
    accountTradingAddressCity?: string,
    accountTradingAddressCountry?: string,
    accountTradingAddressState?: string,
    accountTradingAddressZip?: string,
    productCommsConsent: boolean,
    creditSearchConsent: boolean,
    tcpaConsent: boolean,
    partner: string;
    averageTransactionVolumes?: number;
}

export interface Applicant {
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    ownershipPercentage: number,
    birthDate: Date,
    dobDay?: string;
    dobMonth?: string,
    dobYear?: string,
    socialSecurityNumber?: string
}

export enum BusinessOwnership {
    FullOwnership = 1,
    CoOwnership,
    Other
}

export interface Address {
    street?: string,
    city?: string,
    country?: string,
    state?: string,
    zip?: string,
}

export enum BusinessType {
    SoleProp = 'Sole Proprietor',
    Corporation = 'Corporation',
    LLC = 'LLC',
    Partnership = 'Partnership'
}

const storeApplicationSession = (application: Application) => {
    localStorage.setItem('session', JSON.stringify(application));
}

const getApplicationSession = () => {
    const saved = localStorage.getItem('session');
    if (saved) {
        return JSON.parse(saved);
    }
    return;
}

const initialApplication: Application = {
    businessEIN: '',
    businessName: '',
    timeInBusiness: { years: undefined, months: undefined },
    address: {
        street: '',
        city: '',
        country: '',
        state: '',
        zip: '',
    },
    businessType: undefined,
    ownershipStructure: undefined,
    applicants: [],
    productCommsConsent: false,
    creditSearchConsent: false,
    tcpaConsent: false,
    averageTransactionVolumes: undefined
}

const App = () => {

    const copy = useContext(CopyContext);

    const getConfig = async () => {
        const settings = await axios.get('/settings/journey-config.json');
        setConfig(settings.data);
        setPageTitle();
    }

    const setPageTitle = () => {
        if(!!copy.tradingAs){
            document.title = `Liberis - in partnership with ${copy.tradingAs}`;
        }
    }

    const setApplication = (application: Application) => {
        storeApplicationSession(application);
        updateApplication(application);
        return;
    }

    mixpanel.init('f5c25a483665d4fe50968e530f12ad1d', { debug: true });

    const trackPageView = (pageName: string) => {
        mixpanel.track('Page View', {
            'page': pageName
        })
    }

    const trackEntityType = (entityType: string) => {
        mixpanel.track('Entity Selected', {
            'entity': entityType
        })
    }

    const [application, updateApplication] = useState<Application>(initialApplication);
    const [config, setConfig] = useState<Config>({
        useAccessCode: false,
        calendlyUrl: '',
        applicationSubmissionUrl: '',
        plaidApiUrl: '',
        validateUrl: '',
        partner: ''
    });

    const BusinessDetails = () => {
        return <PageLayout currentStep={0} percentComplete={10} trackPage={() => trackPageView('BusinessDetails')} leftContent={<LeftConfirmDetailsStart />} rightContent={<RightBusinessDetails showBackButton={false} application={application} updateApplication={setApplication} nextPage={changePage} />} />
    }

    const BusinessEntity = () => {
        return <PageLayout currentStep={0} percentComplete={30} trackPage={() => trackPageView('BusinessEntity')} leftContent={<LeftConfirmDetailsStart />} rightContent={<RightBusinessEntity trackEntity={trackEntityType} showBackButton={true} application={application} updateApplication={setApplication} nextPage={changePage} />} />
    }

    const BusinessOwnership = () => {
        return <PageLayout currentStep={0} percentComplete={50} trackPage={() => trackPageView('BusinessOwnership')} leftContent={<LeftConfirmDetailsStart />} rightContent={<RightBusinessOwnership showBackButton={true} application={application} updateApplication={setApplication} nextPage={changePage} />} />
    }

    const Applicant = () => {
        return <PageLayout currentStep={0} percentComplete={70} trackPage={() => trackPageView('Applicant')} leftContent={<LeftConfirmDetailsStart />} rightContent={<RightOwnerDetails showBackButton={true} multipleOwners={false} application={application} updateApplication={setApplication} nextPage={changePage} />} />
    }

    const Applicants = () => {
        return <PageLayout currentStep={0} percentComplete={70} trackPage={() => trackPageView('Applicants')} leftContent={<LeftConfirmDetailsStart />} rightContent={<RightOwnerDetails showBackButton={true} multipleOwners={true} application={application} updateApplication={setApplication} nextPage={changePage} />} />
    }

    const PlaidSetup = () => {
        return <PageLayout currentStep={1} percentComplete={90} trackPage={() => trackPageView('PlaidSetup')} leftContent={<LeftPlaidConnection />} rightContent={<RightPlaidSubmission config={config} showBackButton={true} liberisId={'abc1234'} applicationId='anApplicationIdJourney' nextPage={changePage} sendApplication={submitApplication} />} />
    }

    const CallBooking = () => {
        return <PageLayout currentStep={2} trackPage={() => trackPageView('CallBooking')} leftContent={<LeftScheduleCall />} rightContent={<RightCalendar config={config} name={`${application?.applicants[0]?.firstName} ${application?.applicants[0]?.lastName}`} email={application?.applicants[0]?.emailAddress} phoneNumber={application?.applicants[0]?.phoneNumber} />} />
    }

    const changePage = (currentRoute: string, nextRoute: string) => {
        localStorage.setItem('currentPage', nextRoute)
        window.history.pushState({}, '', currentRoute); window.location.href = nextRoute;
    }

    useEffect(() => {
        getConfig();
        const savedSession = getApplicationSession();
        // const currentRoute = localStorage.getItem('currentPage');
        // if (currentRoute && window.location.pathname.replace('/', '') !== currentRoute) {
        //     window.location.href = currentRoute;
        // }
        if (savedSession) {
            updateApplication(savedSession)
        }
    }, [])

    const submitApplication = async () => {
        if (!application.liberisId && !application.applicationId && application !== initialApplication) {
            let monthsInBusiness = 0;
            if (application?.timeInBusiness?.years && application?.timeInBusiness?.months) {
                monthsInBusiness = Number(Number(application.timeInBusiness.years * 12) + Number(application.timeInBusiness.months));
            }

            const applicantRequests = application.applicants.map((app: Applicant, index) => {
                const primary = index === 0;
                const applicant: ApplicantCreate = {
                    isPrimary: primary,
                    firstName: app.firstName,
                    lastName: app.lastName,
                    ownershipPercentage: app.ownershipPercentage,
                    email: app.emailAddress,
                    telephone: app.phoneNumber,
                    birthdate: app.birthDate,
                    socialSecurityNumber: app.socialSecurityNumber
                }
                return applicant;
            })
            const request: ApplicationCreateRequest = {
                currency: 'USD',
                applicants: applicantRequests,
                accountLegalName: application.businessName,
                accountTradingName: application.businessName,
                accountEin: application.businessEIN,
                accountTimeInBusiness: monthsInBusiness,
                accountRegisteredAddressStreet: application.address.street,
                accountRegisteredAddressCity: application.address.city,
                accountRegisteredAddressCountry: application.address.country,
                accountRegisteredAddressState: application.address.state,
                accountRegisteredAddressZip: application.address.zip,
                accountTradingAddressStreet: application.address.street,
                accountTradingAddressCity: application.address.city,
                accountTradingAddressCountry: application.address.country,
                accountTradingAddressState: application.address.state,
                accountTradingAddressZip: application.address.zip,
                accountLegalConstitution: application.businessType!.toString(),
                productCommsConsent: application.productCommsConsent,
                creditSearchConsent: application.creditSearchConsent,
                tcpaConsent: application.tcpaConsent,
                partner: config.partner,
                averageTransactionVolumes: application.averageTransactionVolumes
            }

            const applicationUrl = config?.applicationSubmissionUrl;
            if (!applicationUrl) {
                return;
            }

            const response = await axios.post(applicationUrl, request);
            if (response.status === 200) {
                application.liberisId = response.data.liberisId;
                application.applicationId = response.data.applicationId;
                setApplication(application);
                return response.data;
            }

            return null;
        }

        return { applicationId: application.applicationId, liberisId: application.liberisId }
    }

    return (
        <Theme config={config}>
            <BrowserRouter>
                <Route exact path="/" component={() => { return <Landing config={config} trackPage={() => trackPageView('Landing')} /> }} />
                <Route path="/business-details" component={BusinessDetails} />
                <Route path="/business-entity" component={BusinessEntity} />
                <Route path="/business-ownership" component={BusinessOwnership} />
                <Route path="/applicant" component={Applicant} />
                <Route path="/applicants" component={Applicants} />
                <Route path="/plaid-setup" component={PlaidSetup} />
                <Route path="/complete" component={() => { return <SuccessPage trackPage={() => trackPageView('SuccessPage')} nextPage={changePage} /> }} />
                <Route path="/call-now" component={() => { return <CallNow trackPage={() => trackPageView('CallNow')} /> }} />
                <Route path="/faqs" component={() => { return <FAQs trackPage={() => trackPageView('FAQs')} /> }} />
            </BrowserRouter >
        </Theme>
    )
}

export default App;