import { Col, Row, Layout, Typography, Space, Collapse } from 'antd';
import { Content } from 'antd/lib/layout/layout';
const { Title, Text } = Typography;
const { Panel } = Collapse;

const LeftScheduleCall = () => {
    return (
        <div className='leftContentBody'>
            <Layout>
                <Content>

                    <Row gutter={16}>
                        <Col span={4}>
                        </Col>
                 
                        <Col>
                            <Title className={'leftTitle'} level={3}>Book a call</Title>
                        </Col>
                    </Row>
                    <Space direction="vertical" size={20}>
                        <Row>
                            <Col span={4}>
                            </Col>
                            <Col span={16}>
                                <Text className='leftText'>The next step is to  book a call with Liberis so that they can discuss your quote with you.</Text>
                            </Col>

                            <Col span={4}></Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                            </Col>
                            <Col span={16}>
                                <Collapse className='bookCallCollapse' defaultActiveKey={1} ghost>
                                    <Panel showArrow={false} key="1" header="What should I expect from my call?">Your call with Liberis will last approximately 15 mins.  Here, they will firstly confirm your business details and discuss your funding needs. The team will then agree a funding amount, price, and schedule with you.</Panel>
                                    <Panel showArrow={false} key="2" header="Why do I need to call?">It is important for Liberis to speak directly with you so that they can confirm your details and ensure you completely understand your quote and how payments work!</Panel>
                                    <Panel showArrow={false} key="3" header="What will I need for my call?"><Text className="callNowText">
                                        You will need to have the following information on hand for your call:
                                        <br />
                                        <ol>
                                            <li>Bank account details</li>
                                            <li>Business owner details</li>
                                            <li>Details on your funding requirements</li>
                                        </ol></Text></Panel>
                                    <Panel showArrow={false} key="4" header="What happens after my call?"><Text className="callNowText">
                                        Once Liberis finds a funding amount and price right for you, they will then process your application. During this process, Liberis will look at your business and conduct some checks, such as affordability, credit, and insolvency. Once approved, you will receive the funds to use as you wish for your business!</Text><br />
                                        <Text className="callNowText">Your payments will be taken automatically via a direct debit on a weekly basis until your cash advance and funding fee have been paid. This will continue until the advance is paid off.
                                        </Text></Panel>
                                </Collapse>
                            </Col>
                            <Col span={4}></Col>
                        </Row>
                    </Space>
                </Content>
            </Layout>
        </div>
    );
}

export default LeftScheduleCall;
