import { Config } from "../config";
import SezzleTheme from "./sezzle/SezzleTheme";
import UberTheme from "./uber/UberTheme";
import WayflyerTheme from "./wayflyer/WayflyerTheme";

export type ThemeProps = {
    config: Config;
    children: any;
}

const partnerMap: any = {
    'SezzleCapital': 'SezzleCapital',
    'Uber': 'Uber',
    'ShopCircle': 'Wayflyer'
}

const themes: any = {
    'SezzleCapital': SezzleTheme,
    'Uber': UberTheme,
    'ShopCircle': WayflyerTheme
}

const Theme = (props: ThemeProps) => {
    if (!props.config?.partner) {
        return <div>Loading...</div>
    }

    const PartnerTheme = themes[props.config.partner];
    return (
        <div className={partnerMap[props.config.partner]}>
            <PartnerTheme>
                {props.children}
            </PartnerTheme>
        </div>
    )
}

export default Theme;