import CopyContext from "../CopyContext";
import "./WayflyerTheme.css";
import wayflyerCopy from "./WayflyerCopy";

const WayflyerTheme = (props: any) => {
    return (
        <CopyContext.Provider value={wayflyerCopy}>
            {props.children}
        </CopyContext.Provider>
    )
}

export default WayflyerTheme;