import { Button, Checkbox, Col, Form, Input, InputNumber, Layout, Row, Select, Space, Tooltip, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import AddressSearch from 'react-loqate';
import { useEffect, useState } from 'react';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import ProgressBar from './ProgressBar';
import ButtonFooter from './ButtonFooter';
import { Address, Application } from './App';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
const { Title, Text } = Typography;

const { Option } = Select;


interface RightBusinessDetailsProps {
    showBackButton: boolean;
    application: Application;
    updateApplication: (application: Application) => void;
    nextPage: (currentPage: string, nextPage: string) => void;
}
const RightBusinessDetails = ({ showBackButton, application, updateApplication, nextPage }: RightBusinessDetailsProps) => {

    const onFinish = (values: any) => {
        application.businessEIN = values.businessEIN;
        application.businessName = values.businessName;
        application.address.city = values.city;
        application.address.country = values.country;
        application.address.state = values.state;
        application.address.street = values.street;
        application.address.zip = values.zip;
        application.timeInBusiness = { years: values.years, months: values.months };
        application.averageTransactionVolumes = values.averageTransactionVolumes;
        updateApplication(application);
        nextPage('business-details', 'business-entity');
    };

    const [showAddressEntry, setShowAddressEntry] = useState<boolean>(false);
    const [autoSelectedAddress, setAutoSelectedAddress] = useState<Address>();
    const [form] = Form.useForm();

    const BusinessEINTooltip = () => {
        return <div>
            <div style={{ fontSize: '16px' }}>An Employer Identification Number (EIN) is used to identify your business for tax purposes.</div><br />
            <div><b><span style={{ color: '#8a46f7e8' }}>EIN:</span> 9 numbers (XX-XXXXXXX)</b></div><br />
            <div style={{ textDecoration: 'underline' }}><b>You can find your business EIN number by:</b></div>
            <ul>
                <li>Checking your <b>EIN confirmation letter</b></li>
                <li>Checking your <b>business credit report</b></li>
                <li>Checking your <b>business bank account statements</b></li>
                <li>Checking your <b>official tax notices from the IRS</b></li>
                <li>Checking  old <b>federal tax returns</b></li>
                <li><b>Calling the IRS</b></li>
            </ul>

        </div >
    }

    const BusinessNameTooltip = () => {
        return <div>
            <div>You might have both a <b>legal business name</b> and a trade name.
            </div><br />
            <div><b>Your legal business name </b> is the name you use on your government forms and business paperwork.
            </div>
        </div>
    }


    useEffect(() => {
        if (application.address.city !== '' || application.address.street !== '' || application.address.state !== '' || application.address.country !== '' || application.address.zip !== '') {
            setShowAddressEntry(true)
        }
    }, []);

    return (
        <Layout>
            <Content>
                <Row>
                    <Col xs={2} md={2}></Col>

                    <Col className='formBody' style={{ marginTop: '50px' }} xs={20} md={18}>
                        <Text className='rightTitle'>Confirm your business details
                        </Text>
                        <Form
                            name="basic"
                            requiredMark={false}
                            layout="vertical"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}

                        >
                            <Form.Item
                                label={<div>Business EIN <Tooltip className='tooltipBody' placement="bottomLeft" title={<BusinessEINTooltip />}><QuestionCircleOutlined className='tooltipAnchor' /></Tooltip></div>}
                                name="businessEIN"
                                initialValue={application.businessEIN}
                                validateFirst={true}
                                validateTrigger='onBlur'
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your business EIN.',
                                        pattern: /^[1-9]\d?-\d{7}$/
                                    },
                                ]}
                            >
                                <Input placeholder='Enter your business EIN - 00-0000000' />
                            </Form.Item>

                            <Form.Item
                                label={<div>Business Name <Tooltip className='tooltipBody' placement="bottomLeft" title={<BusinessNameTooltip />}><QuestionCircleOutlined className='tooltipAnchor' /></Tooltip></div>}
                                name="businessName"
                                hasFeedback
                                initialValue={application.businessName}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please tell us your business name.',
                                    },
                                ]}
                            >
                                <Input placeholder='Enter your business name' />
                            </Form.Item>

                            <Row gutter={32}>
                                <Col span={10}>
                                    <Form.Item
                                        name="years"
                                        label="Years"
                                        initialValue={application.timeInBusiness.years}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select your years in business!',
                                            },
                                        ]}
                                    >
                                        <Select optionFilterProp="children" placeholder="Years in business">
                                            {Array.from(Array(21).keys()).map(x => { return <Option value={`${x}`}>{x}</Option> })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="months"
                                        label="Months"
                                        hasFeedback
                                        initialValue={application.timeInBusiness.months}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select your months in business!',
                                            },
                                        ]}
                                    >
                                        <Select optionFilterProp="children" placeholder="Months in business">
                                            {Array.from(Array(13).keys()).map(x => { return <Option value={`${x}`}>{x}</Option> })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                label="Average monthly revenue"
                                name='averageTransactionVolumes'                                
                                initialValue={application.averageTransactionVolumes}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your average monthly revenue.',
                                    },
                                ]}
                            >
                                <InputNumber prefix="$" precision={0} value={application.averageTransactionVolumes} />
                            </Form.Item>

                            <AddressSearch
                                locale="en_GB"
                                apiKey="ZM96-RK12-AA28-ZT16"
                                countries={['US']}
                                components={{ Input: AddressSearchInput }}
                                className="address-search-box"
                                classes={{ list: 'styled-list' }}
                                onSelect={address => {
                                    form.setFieldsValue({
                                        street: address.Line1,
                                        city: address.City,
                                        country: address.CountryName,
                                        state: address.ProvinceName,
                                        zip: address.PostalCode,
                                    });
                                    setShowAddressEntry(true)
                                }}
                                debounce={100}
                            />

                            <div><a className='addressEntryLink' onClick={() => { setShowAddressEntry(true); setAutoSelectedAddress({}) }}>Enter address manually</a></div>

                            {showAddressEntry &&
                                <div>
                                    <Form.Item
                                        label="Street"
                                        name='street'
                                        initialValue={application.address.street}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your business street.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your business street' value={autoSelectedAddress?.street} />
                                    </Form.Item>

                                    <Form.Item
                                        label="City"
                                        name="city"
                                        hasFeedback
                                        initialValue={application.address.city}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your business city.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your business city' value={autoSelectedAddress?.city} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Country"
                                        name="country"
                                        hasFeedback
                                        initialValue={application.address.country}

                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your business country.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your business country' value={autoSelectedAddress?.country} />
                                    </Form.Item>
                                    <Form.Item
                                        label="State"
                                        name="state"
                                        hasFeedback
                                        initialValue={application.address.state}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your business state.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your business state' value={autoSelectedAddress?.state} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Zip"
                                        name="zip"
                                        hasFeedback
                                        initialValue={application.address.zip}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your business zip.',
                                                pattern: /^(\d{5}-\d{4}|\d{5})$|^([a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d)$'/
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter your business zip' value={autoSelectedAddress?.zip} />
                                    </Form.Item>
                                </div>}


                            <ButtonFooter showBackButton={showBackButton} disabled={false} onSubmit={() => { setShowAddressEntry(true) }} />
                        </Form>

                    </Col>
                    <Col xs={2} md={4}></Col>
                </Row>
            </Content >
        </Layout >

    )
}

const AddressSearchInput = (props: any): JSX.Element => {
    return (
        <Form.Item
            label="Business Address"
            name="Business Address"
        >
            <Input {...props} placeholder='Enter your business Address' />
        </Form.Item>
    );
};


export default RightBusinessDetails