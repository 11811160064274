import CopyContext from "../CopyContext";
import "./UberTheme.css";
import uberCopy from "./UberCopy";

const UberTheme = (props: any) => {
    return (
        <CopyContext.Provider value={uberCopy}>
            {props.children}
        </CopyContext.Provider>
    )
}

export default UberTheme;