import { Col, Row, Layout, Typography, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import {useContext} from "react";
import CopyContext from "./partners/CopyContext";
const { Title, Text } = Typography;

const LeftPlaidConnection = () => {
    const copy = useContext(CopyContext);
    return (
        <div className='leftContentBody'>
            <Layout style={{ borderRight: '3px solid #CFB8FF', height: '450px' }}>
            <Content>

                <Row gutter={16}>
                    <Col span={4}>
                    </Col>
                    <Col>
                        <Title className={'leftTitle'} level={3}>Connect Account</Title>
                    </Col>
                </Row>
                <Space direction="vertical" size={30}>
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={16}>
                            <Text className='leftText leftTextLarge'>Connect your account so that we can generate your quote!</Text>
                        </Col>

                        <Col span={4}></Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={16}>
                            <div> <Text className='leftSubtitle'>What is Plaid?
                            </Text></div>
                            <Text className='leftText'>
                                Plaid is a world leading open banking platform that allows Liberis to access your finanical data.

                            </Text></Col>
                        <Col span={4}></Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={16}>
                            <div> <Text className='leftSubtitle'>Why should I connect to Plaid?
                            </Text></div>
                            <Text className="plaidHighlight">
                                Connecting to Plaid is a mandatory step in your application. We need this to complete your application.
                            </Text>
                            <Text className='leftText'>
                                {copy.plaidBody2}
                            </Text></Col>
                        <Col span={4}></Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                        </Col>
                        <Col span={16}>
                            <div> <Text className='leftSubtitle'>What do I need to connect to Plaid?
                            </Text></div>
                            <Text className='leftText'>
                                {copy.plaidBody3}
                            </Text></Col>
                        <Col span={4}></Col>
                    </Row>

                </Space>
            </Content>
        </Layout>
        </div >
    );
}

export default LeftPlaidConnection;
