import { Button, Card, Col, Form, Input, Layout, Row, Select, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import AddressSearch from 'react-loqate';
import { useContext, useEffect, useState } from 'react';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import ProgressBar from './ProgressBar';
import classNames from 'classnames';
import ButtonFooter from './ButtonFooter';
import { Application, BusinessType } from './App';
import CopyContext from "./partners/CopyContext";

const { Text } = Typography;



interface RightBusinessDetailsProps {
    showBackButton: boolean;
    application: Application;
    updateApplication: (application: Application) => void;
    nextPage: (currentPage: string, nextPage: string) => void;
    trackEntity: (entityType: string) => void
}
const RightBusinessEntity = ({ showBackButton, application, updateApplication, nextPage, trackEntity }: RightBusinessDetailsProps) => {

    const [selectedBusinessType, selectBusinessType] = useState<BusinessType>()
    const copy = useContext(CopyContext);

    useEffect(() => {
        if (application.businessType) {
            selectBusinessType(application.businessType)
        }
    }, [])

    return (
        <div>
            <Layout>
                <Content>
                    <Row>
                        <Col xs={2} md={4}></Col>
                        <Col className='formBody' style={{ marginTop: '50px' }} xs={20} md={16}>
                            <Text className='rightTitle'>Select your business entity type
                            </Text>
                            <Row gutter={32} className='companyTypes'>
                                <Col xs={24} md={8}>
                                    <Card bordered={false} className={classNames({ 'selectedCard': selectedBusinessType === BusinessType.Corporation })} onClick={() => { selectBusinessType(BusinessType.Corporation); trackEntity(BusinessType.Corporation) }} hoverable>
                                        <div style={{ marginTop: '10px', marginBottom: '13px' }}>
                                            <div className='corporation'></div>
                                        </div>
                                        <div>{copy.corporationBody} <br />  <b>Ownership is determined by stock.</b></div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card bordered={false} className={classNames({ 'selectedCard': selectedBusinessType === BusinessType.LLC })} onClick={() => { selectBusinessType(BusinessType.LLC); trackEntity(BusinessType.LLC) }} hoverable>
                                        <div style={{ marginTop: '10px', marginBottom: '13px' }}>
                                            <div className='llc'></div>
                                        </div>
                                        <div>You business has 2+ owners and exists separately from them. <br />  <b>Ownership is distributed freely. </b></div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card bordered={false} className={classNames({ 'selectedCard': selectedBusinessType === BusinessType.SoleProp })} onClick={() => { selectBusinessType(BusinessType.SoleProp); trackEntity(BusinessType.SoleProp) }} hoverable>
                                        <div style={{ marginTop: '10px', marginBottom: '13px' }}>
                                            <div className='soleprop'></div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={0}></Col>
                                <Col span={24}><ButtonFooter showBackButton={showBackButton} disabled={selectedBusinessType == null} onSubmit={() => { application.businessType = selectedBusinessType; updateApplication(application); nextPage('business-entity', 'business-ownership') }} />
                                </Col>
                                <Col span={0}></Col>
                            </Row>
                        </Col>
                        <Col xs={2} md={3}></Col>
                    </Row>
                </Content>
            </Layout>
        </div>)
}


export default RightBusinessEntity