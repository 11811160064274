import { InlineWidget } from "react-calendly";
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import {Config} from "./config";

interface CalendarProps {
    name: string,
    email: string,
    phoneNumber?: string,
    config: Config;
}

function isCalendlyEvent(e: any) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};


const RightCalendar = ({ name, email, config }: CalendarProps) => {
    const calendlyUrl = config.calendlyUrl ? config.calendlyUrl : '';

    useEffect(() => {
        window.addEventListener("message", function (e: any) {
            if (isCalendlyEvent(e)) {
                if (e.data.event === 'calendly.event_type_viewed') {
                    setCalendarLoaded(true);
                }
            }
        });
    }, [])
    const [calendarLoaded, setCalendarLoaded] = useState<boolean>(false);
    return (
        <div className="rightCalendar">
            {!calendarLoaded &&
                <div className='loading'>
                    <LoadingOutlined style={{ fontSize: 60, color: '#8a46f7e8' }} spin />
                </div>
            }
            <InlineWidget styles={{ height: '900px' }} url={calendlyUrl} prefill={{
                "name": name,
                "email": email,
            }} pageSettings={{
                "backgroundColor": "ffffff",
                "hideEventTypeDetails": true,
                "hideLandingPageDetails": true,
                "primaryColor": "8a46f7e8",
                "textColor": "8a46f7e8",
                "hideGdprBanner": true
            }} />
        </div>
    );
}

export default RightCalendar